import logo from './logo.svg';
import './App.css';
import Indexer from './components/Indexer';
import wrapper from "./store/store";
import WalletProvider from './components/WalletProvider';

function App() {
  return (
    <WalletProvider>
      <Indexer />
    </WalletProvider>
  );
}

export default App;
