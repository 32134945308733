import React, {useState, useEffect} from 'react'

import { 
  Typography,
  Card,
  CardBody,
  Input,
  Slider,
  Button,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel, 
  slider
} from "@material-tailwind/react";
import { v4 as uuidv4 } from 'uuid';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import '../custom-toast.css';

export default function NatConicsViewer() {
  const [blockNumber, setBlockNumber] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [imgNumber, setImgNumber] = useState(0);

  const handleChange = (e) => {
    setBlockNumber(e.target.value);
  }

  const handleGenerate = (e) => {
    setImgNumber(blockNumber);
  }

  const validImageNumber = (imgNumber) => {
    if ((imgNumber >= 828576) && (imgNumber <= 830591))
      return true;
    if ((imgNumber >= 622944) && (imgNumber <= 624959))
      return true;
    if ((imgNumber >= 122976) && (imgNumber <= 124991))
      return true;
    if ((imgNumber >= 42336) && (imgNumber <= 44351))
      return true;
    return false;
  }
  return (
    <div className="flex md:flex-row flex-col md:gap-[80px] gap-8 mt-[80px] items-center justify-center">
      <div className="flex flex-col gap-2 max-w-[300px]">
        <div className=" font-bold text-[36px]">Natconics Viewer</div>
        <div className=" text-[20px]">Enter a $DMT-Natconics block number to generate a NatConics.</div>
        <div className="w-[200px] mt-5">
          <Input label="Block number" className="font-bold text-[24px]" value={blockNumber} onChange = {handleChange} />
        </div>
        <Button className="mt-4 w-[150px]" onClick={handleGenerate}>Generate</Button>
        <a className=" text-[#4747FF] mt-6 underline" href="https://natconics.com/" target="_blank">Official Website</a>
        <a className=" text-[#4747FF] underline" href="https://natconics.gitbook.io/natconics" target="_blank">Gitbook</a>
      </div>
      <div className="relative">
        <div className="w-[400px] h-[400px] bg-gray-700"></div>
        {
          validImageNumber(imgNumber) ? 
          <img src={`https://block.viewer.natconics.com/images/${imgNumber}.png`} className="absolute left-0 top-0 w-[400px] h-[400px] "/>
          :
          <></>
        }
        {/*<div className="w-[400px] text-center font-bold text-[20px] text-blue-700">{name}</div>*/}
      </div>
      <ToastContainer />
    </div>
  )
}
